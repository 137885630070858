import React from 'react';
import css from './TotalApptBox.module.css';
import { useTranslation } from 'react-i18next';
import { VetWithAppointmentCount } from '_fsd/entities/vet';

export const TotalApptBox = ({ data, length }) => {
  const { t } = useTranslation();
  return (
    <div className={css.totalAppointments}>
      <div className={css.totalsHeader}>
        <span className={css.titleText}>{t('admin_dashboard.total_appointments')}</span>
        <b>{length}</b>
      </div>
      <div className={css.lineBreak} />
      <div className={css.totalsList}>
        {data?.map((appt) => {
          return <VetWithAppointmentCount appt={appt} key={appt.uid} />;
        })}
      </div>
    </div>
  );
};
