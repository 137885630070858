import React, { useMemo } from 'react';

import css from './VerAdminDashboard.module.css';
import { getLast12MonthsStartAndEndDates } from 'utils/helpers';
import { MonthlyAppointmentSummaryChart } from '_fsd/features/monthly-appointment-summary-chart';
import { AppointmentHistoryTable } from '_fsd/widgets/appointment-history-table';
import { TotalApptBox } from '_fsd/widgets/total-appointments-box';
import { useAppointmentsByVetAdmin } from '_fsd/entities/appointment';
import { useDashboardData } from '../lib';

export const VetAdminDashboard = () => {
  const { startDate, endDate } = useMemo(() => getLast12MonthsStartAndEndDates(), []);
  const { data, loading } = useAppointmentsByVetAdmin({ startDate, endDate });

  const { monthlyData, chartsData, apptsByCaregiver } = useDashboardData({
    data
  });

  return (
    <div className={css.container}>
      <div className={css.flex}>
        <TotalApptBox data={apptsByCaregiver} length={monthlyData?.length} />
        <AppointmentHistoryTable data={monthlyData} loading={loading} />
      </div>
      <MonthlyAppointmentSummaryChart data={chartsData} />
    </div>
  );
};
