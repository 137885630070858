import React from 'react';
import { UserDynamicAvatar } from '_fsd/shared';

export const useColumns = () => {
  return [
    {
      showSorterTooltip: false,
      sorter: (a, b) => new Date(a.date) - new Date(b.date),
      title: 'Appt date',
      dataIndex: 'date',
      key: 'date'
    },

    {
      showSorterTooltip: false,
      sorter: (a, b) => a.client.localeCompare(b.client),
      title: 'client',
      dataIndex: 'client',
      key: 'client'
    },
    {
      showSorterTooltip: false,
      sorter: (a, b) => a.caregiver.localeCompare(b.caregiver),
      title: 'Caregiver',
      dataIndex: 'caregiver',
      key: 'caregiver',
      render: (t, record) => (
        <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
          <UserDynamicAvatar user={record.vetUser} />
          <span>{record.caregiver}</span>
        </div>
      )
    }
  ];
};
