import { useMemo } from 'react';

const getMonthYear = (dateStr) => {
  const [year, month] = dateStr.split('-');
  return `${year}-${month}`;
};

export const useChartData = (data = []) => {
  return useMemo(() => {
    const monthCaregiverCounts = {};
    data?.forEach((entry) => {
      const caregiver = entry.caregiver;
      const monthYear = getMonthYear(entry.date);
      if (!monthCaregiverCounts[monthYear]) {
        monthCaregiverCounts[monthYear] = {};
      }
      if (!monthCaregiverCounts[monthYear][caregiver]) {
        monthCaregiverCounts[monthYear][caregiver] = 0;
      }
      monthCaregiverCounts[monthYear][caregiver] += 1;
    });
    const chartData = Object.keys(monthCaregiverCounts).map((month) => ({
      name: month,
      month: new Date(month).getMonth(),
      users: Object.keys(monthCaregiverCounts[month]).map((caregiver) => ({
        name: caregiver,
        amt: monthCaregiverCounts[month][caregiver]
      }))
    }));

    const allNames = Array.from(
      new Set(chartData.flatMap((entry) => entry.users.map((user) => user.name)))
    );

    const flattenedData = chartData?.map((month) => {
      const monthData = { name: month.name };
      allNames.forEach((name) => {
        const user = month.users.find((user) => user.name === name);
        monthData[name] = user ? user.amt : 0;
      });
      return monthData;
    });
    return { flattenedData, allNames };
  }, [data]);
};
