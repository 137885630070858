import { useChartData } from '_fsd/features/monthly-appointment-summary-chart';

const filterCurrentMonthData = (data) => {
  const now = new Date();
  const currentYear = now.getFullYear();
  const currentMonth = now.getMonth();

  return data?.filter((item) => {
    const itemDate = new Date(item.date);
    return itemDate.getFullYear() === currentYear && itemDate.getMonth() === currentMonth;
  });
};

const countByCaregivers = (data) => {
  const caregiverCounts = {};
  data?.forEach((entry) => {
    const caregiver = entry.caregiver;
    const vetUser = entry.vetUser;
    if (!caregiverCounts[caregiver]) {
      caregiverCounts[caregiver] = {
        count: 0,
        vetUser
      };
    }
    caregiverCounts[caregiver].count += 1;
  });
  const apptsByCaregiver = Object.keys(caregiverCounts).map((caregiver) => ({
    caregiver: caregiver,
    count: caregiverCounts[caregiver].count,
    vetUser: caregiverCounts[caregiver].vetUser
  }));
  return apptsByCaregiver;
};

export const useDashboardData = ({ data }) => {
  const dataSource = data?.map(({ date, uid, user, timeSlot }, index) => {
    return {
      uid,
      date,
      caregiver: `${timeSlot.vet.user.firstName} ${timeSlot.vet.user.lastName}`,
      client: `${user.firstName} ${user.lastName}`,
      vetUser: timeSlot.vet.user
    };
  });

  const monthlyData = filterCurrentMonthData(dataSource);
  const apptsByCaregiver = countByCaregivers(monthlyData);
  const chartsData = useChartData(dataSource);

  return {
    apptsByCaregiver,
    monthlyData,
    chartsData
  };
};
