import React from 'react';
import moment from 'moment';
import { EyeTwoTone, EditTwoTone, MoreOutlined } from '@ant-design/icons';
import { Popover } from 'antd';
import css from './Customers.module.css';

import './customers-styles.css';
import useActAsUser from './useActAsUser';
import { cssText, Dropdown, Button } from '_fsd/shared';
import WarningIcon from 'assets/icons/warning-circle-red-filled.svg';

import { petAvatars } from 'constants/pet';
import { UserContactInfoPopover } from '_fsd/features/user-contact-info-popover/ui/UserContactInfoPopover.jsx';

import { useTranslation } from 'react-i18next';
import cls from 'classnames';
import { INTEGRATION_ENTITY_STATUS } from '_fsd/entities';

const ActionColumn = ({ record, actAsHandler, editPetOwnerHandler }) => {
  const items = [
    {
      key: 2,
      label: (
        <div className={css.actionItem} onClick={() => editPetOwnerHandler(record)}>
          <EditTwoTone twoToneColor={'#112950'} />
          <span>Edit client</span>
        </div>
      )
    },
    {
      key: 1,
      label: (
        <div className={css.actionItem} onClick={() => actAsHandler(record.userId)}>
          <EyeTwoTone twoToneColor={'#112950'} />
          <span>View as client</span>
        </div>
      )
    }
  ];
  return (
    <Dropdown
      menu={{ items }}
      trigger={['click']}
      getPopupContainer={() => {
        return document.getElementById('ID_LAYOUT_SCROLLABLE');
      }}>
      <MoreOutlined />
    </Dropdown>
  );
};

export const useColumns = (sortBy, setEditClient) => {
  const { handleActAsUser } = useActAsUser();
  const { t } = useTranslation();

  const editPetOwnerHandler = (user) => {
    setEditClient(user);
  };

  const actAsHandler = (userId) => {
    handleActAsUser(userId);
  };

  return [
    {
      title: t('vet_admin.clients_table.registration_date'),
      dataIndex: 'registrationDate',
      key: 'registrationDate',
      showSorterTooltip: false,
      sorter: true
    },
    {
      title: <span className={css.fullNameTitle}>{t('vet_admin.clients_table.full_name')}</span>,
      dataIndex: 'fullName',
      key: 'fullName',
      showSorterTooltip: false,
      sorter: true,
      render: (text, record) => {
        // const isAnyIntegrationError = record.petOwnerIntegrations?.some(
        //   (integration) => integration.status === INTEGRATION_ENTITY_STATUS.ERROR
        // );

        return (
          <div className={css.fullNameRow}>
            <Popover
              overlayClassName={css.errorPopover}
              content={
                <div className={css.errorPopoverContent}>
                  <div className={cssText.s14w6l18}>PIMS sync issue</div>
                  {record.petOwnerIntegrations?.map(
                    (integration) =>
                      integration?.errorDetails?.message && (
                        <span key={integration.uid}>{integration?.errorDetails?.message}</span>
                      )
                  )}
                  <Button className={css.errorPopoverButton}>Fix it</Button>
                </div>
              }>
              <img
                className={cls(
                  css.warningIntegrationIcon,
                  css.hiddenIntegrationIcon
                  //   {
                  //   [css.hiddenIntegrationIcon]: !isAnyIntegrationError
                  // }
                )}
                src={WarningIcon}
              />
            </Popover>
            {record.fullName}
          </div>
        );
      }
    },
    {
      title: t('vet_admin.clients_table.contacts'),
      dataIndex: 'contacts',
      align: 'center',
      key: 'contacts',
      render: (text, record) => (
        <UserContactInfoPopover
          phonePrefix={record.phone}
          phoneNumber={''}
          email={record.email}
          city={record.city}
          street={record.street}>
          @
        </UserContactInfoPopover>
      )
    },
    {
      showSorterTooltip: false,
      sorter: true,
      title: t('vet_admin.clients_table.pets_number'),
      dataIndex: 'petAmount',
      align: 'center',
      key: 'petAmount',
      render: (text, record) => {
        // const isAnyPetIntegrationError = record?.pets?.some((pet) =>
        //   pet.petIntegrations.some(
        //     (integration) => integration.status === INTEGRATION_ENTITY_STATUS.ERROR
        //   )
        // );

        return (
          <div className={css.petsRow}>
            <img
              src={WarningIcon}
              className={cls(
                css.warningIntegrationIcon,
                css.hiddenIntegrationIcon
                // {
                //   [css.hiddenIntegrationIcon]: !isAnyPetIntegrationError
                // }
              )}
            />
            <Popover
              overlayClassName={css.errorPopover}
              content={
                record?.pets?.length && (
                  <div className={css.errorPopoverContent}>
                    {record?.pets?.map((pet) => (
                      <div key={pet.uid} className={css.errorPopoverPetsContent}>
                        <div className={css.errorPetIconContainer}>
                          {/* {pet.petIntegrations.some(
                            (i) => i.status === INTEGRATION_ENTITY_STATUS.ERROR
                          ) && <img src={WarningIcon} className={css.waringPetIcon}></img>} */}
                          <img
                            className={css.petIcon}
                            src={pet?.avatarUrl || petAvatars[pet?.type?.name || 'other']}
                          />
                        </div>
                        <span>{pet.name}</span>
                      </div>
                    ))}
                  </div>
                )
              }>
              <div style={{ cursor: 'pointer' }}>{record.petAmount}</div>
            </Popover>
          </div>
        );
      }
    },
    {
      showSorterTooltip: false,
      sorter: false,
      title: t('vet_admin.clients_table.next_appointment_date'),
      dataIndex: 'nextAppointmentDate',
      align: 'center',
      key: 'nextAppointmentDate',
      render: (text, record) => (
        <div>
          {record?.userNextAppointmentDate
            ? moment(record.userNextAppointmentDate).format('DD/MM/YYYY')
            : ''}
        </div>
      )
    },
    {
      title: t('vet_admin.clients_table.action'),
      key: 'action',
      dataIndex: 'action',
      align: 'right',
      render: (text, record) => (
        <ActionColumn
          actAsHandler={actAsHandler}
          editPetOwnerHandler={editPetOwnerHandler}
          record={record}
        />
      )
    }
  ].filter((item) => item?.visible !== false);
};

export default useColumns;
