import { geocodeByPlaceId, getLatLng } from 'react-places-autocomplete';
import { BOOK, MEMBERSHIP_SEQUENCE } from 'constants/client';
import { rrulestr } from 'rrule';
import dayjs from 'dayjs';
import moment from 'moment';
import uniqueId from 'lodash/uniqueId';
import get from 'lodash';

export const formatTime = (seconds) => {
  const minutes = Math.floor(seconds / 60);
  const secs = seconds % 60;
  const formattedSeconds = secs < 10 ? `0${secs}` : secs;
  return `${minutes}:${formattedSeconds}`;
};

export const range = (min, max, direction = 'asc') => {
  return Array.from(new Array(max + 1 - min), (x, i) =>
    direction === 'desc' ? max - i : i + min
  ).map((i) => ({
    value: i,
    label: i
  }));
};

export const months = [
  'january',
  'february',
  'march',
  'april',
  'may',
  'june',
  'july',
  'august',
  'september',
  'october',
  'november',
  'december'
];

export const formatFileSize = (size) => {
  const oneKB = 1024;
  const oneMB = 1024 * 1024;
  if (size < oneMB) {
    return `${(size / oneKB).toFixed(1)}KB`;
  }
  return `${(size / oneMB).toFixed(1)}MB`;
};

export const getInitialLetters = (fullname, firstName, lastName) => {
  let firstInitial = '';
  let lastInitial = '';
  if (fullname) {
    const splitted = fullname.split(' ');
    firstInitial = splitted?.[0]?.[0];
    lastInitial = splitted?.[1]?.[0];
  } else {
    firstInitial = firstName?.[0];
    lastInitial = lastName?.[0];
  }
  if (firstInitial && lastInitial) {
    return `${firstInitial}${lastInitial}`.toUpperCase();
  }
  return '';
};

export const toggleChat = (e, history) => {
  const { location } = history;
  e?.preventDefault();
  history.push({ ...history, search: location.search ? '' : '?chatOpen=true' });
};

export const getStepStatus = (route, index, location) => {
  return location === route
    ? 'active'
    : MEMBERSHIP_SEQUENCE.findIndex((item) => item === location) < index
      ? 'passive'
      : 'done';
};

export const weekDayNumbers = {
  sunday: 0,
  monday: 1,
  tuesday: 2,
  wednesday: 3,
  thursday: 4,
  friday: 5,
  saturday: 6
};

// timeslots to date object with timeSlots
export const timeSlotsToDateObject = (timeSlots, currenthMonth, fullTimeSlots) => {
  const datesObj = {};
  // make timeslots as an object ("date" - timeslots[])(key - value)
  timeSlots.forEach((slot) => {
    if (slot.rule) {
      const oneYearFromNow = new Date();
      oneYearFromNow.setFullYear(oneYearFromNow.getFullYear() + 1);
      rrulestr(slot.rule).between(new Date(), oneYearFromNow, false, (date) => {
        // set timeslot's hour and minute to date
        date.setHours(Number(slot?.startTime.slice(0, slot?.startTime.length - 6)));
        date.setMinutes(Number(slot?.startTime.slice(3, slot?.startTime.length - 3)));
        // if currenthMonth is true it will return next 30 days dates
        if (currenthMonth) {
          const today = new Date();
          const future = new Date();
          future.setDate(future.getDate() + 30);

          if (date.getTime() >= today.getTime() && date.getTime() <= future.getTime()) {
            setDateToObject(datesObj, date, slot);
          }
        } else {
          setDateToObject(datesObj, date, slot);
        }
        return date;
      });
    }
  });

  // if timeslot is full remove date from list or disable single timeslot
  if (fullTimeSlots && !!fullTimeSlots.length) {
    fullTimeSlots.forEach((ft) => {
      const timeSlots = datesObj[ft.date];
      if (timeSlots) {
        timeSlots.forEach((t) => {
          if (t.uid === ft.timeSlotId) {
            t.full = true;
          }
        });
        const disabledLength = timeSlots.filter((t) => t.full === true).length;
        if (disabledLength > 0 && disabledLength == timeSlots.length) {
          delete datesObj[ft.date];
        }
      }
    });
  }
  return datesObj;
};

// create object { date : [timeSlots]}
const setDateToObject = (obj, date, slot) => {
  const formatedDate =
    localStorage.getItem('lang') === 'heb'
      ? dayjs(date).format('YYYY-MM-DD')
      : dayjs(date).format('YYYY-DD-MM');
  // if key already exists just push timeslot
  if (formatedDate in obj) {
    obj[formatedDate] = [...obj[formatedDate], { ...slot, date }];
  } else obj[formatedDate] = [{ ...slot, date }];
};

export const datesObjectToTimeSlots = (currentMonthTimeSlots) => {
  return Object.entries(currentMonthTimeSlots).reduce((allTimeSlots, [date, timeSlots]) => {
    return allTimeSlots.concat(timeSlots);
  }, []);
};

// get dates between two dates
export const getRruleDatesBetween = (rule, slot, start, end) => {
  return rule.between(start, end).map((r) => {
    const date = new Date(r.getTime());
    // set timeslot's hour and minute to date
    date.setHours(Number(slot?.startTime.slice(0, slot?.startTime.length - 6)));
    date.setMinutes(Number(slot?.startTime.slice(3, slot?.startTime.length - 3)));
    return {
      ...slot,
      date
    };
  });
};

// set dates array to timeslot object
export const setDatesToTimeSlot = (timeSlots) => {
  const dates = [];
  timeSlots.forEach((slot) => {
    if (slot.rule) {
      const rule = rrulestr(slot.rule);
      slot.dates = rule.all();
      dates.push(slot);
    }
  });
  return dates;
};

// get current months dates
export const getCurrentMonthRules = (slot) => {
  const rule = rrulestr(slot.rule);
  const today = new Date();
  const lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 1);

  let dates = getRruleDatesBetween(rule, slot, today, lastDayOfMonth);

  // if there is no timeslots in current month, find it in the next one
  if (dates.length < 7) {
    let nextMonth;
    if (today.getMonth() == 11) {
      nextMonth = new Date(today.getFullYear() + 1, 1);
    } else {
      nextMonth = new Date(today.getFullYear(), today.getMonth() + 2, 0);
    }
    dates = [...dates, ...getRruleDatesBetween(rule, slot, lastDayOfMonth, nextMonth)];
  }

  return dates;
};

export const parseTimeSlotHour = (hour) => {
  return dayjs()
    .set('hour', Number(hour.split(':')[0]))
    .set('minute', Number(hour.split(':')[1]))
    .format('h:mm a')
    .toUpperCase();
};

export const replaceRouteParams = (routeName, paramName, alternateParam) => {
  return routeName.replace(`:${paramName}`, alternateParam);
};

const currencies = {
  USD: '$',
  ILS: '₪'
};
export const getCurrencyIcon = (currency) => {
  return currencies?.[currency?.name];
};

export const executeGoogleScript = (currency, amount) => {
  if (process.env.REACT_APP_NODE_ENV === 'production' && window.dataLayer) {
    window.gtag('event', 'conversion', {
      send_to: 'AW-769802976/yiwWCPz91rEBEOCFie8C',
      value: amount,
      currency: currency,
      transaction_id: ''
    });
  }
};

export const executeFbScript = (currency, amount) => {
  if (process.env.REACT_APP_NODE_ENV === 'production') {
    window.fbq('track', 'Purchase', { currency: currency, value: amount });
  }
};

export const orderConverter = (orderType) => {
  if (orderType === 'ascend') {
    return 'asc';
  } else {
    return 'desc';
  }
};

export const getBookingUrl = () => {
  return BOOK.replace(':step?', '');
};

const getMiddle = (prop, markers) => {
  let values = markers.map((m) => m[prop]);
  let min = Math.min(...values);
  let max = Math.max(...values);
  if (prop === 'lng' && max - min > 180) {
    values = values.map((val) => (val < max - 180 ? val + 360 : val));
    min = Math.min(...values);
    max = Math.max(...values);
  }
  let result = (min + max) / 2;
  if (prop === 'lng' && result > 180) {
    result -= 360;
  }
  return result;
};

export const findCenter = (markers) => {
  return {
    lat: getMiddle('lat', markers),
    lng: getMiddle('lng', markers)
  };
};

export const isJsonString = (str) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

export const getVetCalendarUrl = (vet) => {
  return `${process.env.REACT_APP_PETS_WEB_ROOT_URL}/schedule/${vet?.user?.firstName
    ?.replaceAll('-', '_')
    ?.replaceAll(' ', '_')}-${vet?.user?.lastName
    ?.replaceAll('-', '_')
    ?.replaceAll(' ', '_')}-${vet?.shareId}`;
};

export const getTimeSlotTime = (time) => {
  const date = dayjs()
    .set('hour', Number(time.split(':')[0]))
    .set('minute', Number(time.split(':')[1]));
  return date.format('h:mm a')?.toUpperCase();
};

export const getAddressByPlaceId = (placeId, onSelect) => {
  geocodeByPlaceId(placeId).then((res) => {
    getLatLng(res[0]).then(({ lat, lng }) => {
      const addr = {
        countryCode: '',
        city: '',
        street: '',
        houseNumber: '',
        zipCode: '',
        description: res[0]?.formatted_address,
        placeId,
        lat: lat,
        lng: lng
      };
      for (const component of res[0].address_components) {
        const componentType = component.types[0];

        switch (componentType) {
          case 'street_number': {
            addr.houseNumber = parseInt(component.short_name);
            break;
          }

          case 'route': {
            addr.street = component.long_name;
            break;
          }

          case 'postal_code': {
            addr.zipCode = component.long_name;
            break;
          }
          case 'political': {
            addr.city = component.long_name;
            break;
          }

          case 'locality':
            addr.city = component.long_name;
            break;

          case 'country':
            addr.countryCode = component.short_name;
            break;
          case 'administrative_area_level_1':
            addr.state = component.long_name;
            break;
          default: {
            break;
          }
        }
      }

      onSelect(addr);
    });
  });
};

export const sortByStartTime = (data) => {
  return data?.sort(
    (a, b) =>
      Date.parse('1970/01/01 ' + a.startTime.slice(0, -2) + ' ' + a.startTime.slice(-2)) -
      Date.parse('1970/01/01 ' + b.startTime.slice(0, -2) + ' ' + b.startTime.slice(-2))
  );
};

export const validatePoint = (point) =>
  typeof point?.lat === 'number' && typeof point.lng === 'number';

export const getOptions = (arr, map = { label: 'value', value: 'uid' }) =>
  arr.map((v) => ({
    value: v[map.value],
    label: typeof map.label === 'function' ? map.label(v) : v[map.label]
  }));

export const birthDayDateToNumbers = (date) => {
  if (!date || !moment(date).isValid())
    return { year: undefined, month: undefined, day: undefined };
  const current = moment();
  const target = moment(date);
  const year = current.diff(target, 'year');
  const month = current.subtract(year, 'year').diff(target, 'month');
  const day = current.subtract(month, 'month').diff(target, 'day');
  return { year, month, day };
};

export const birthDayNumbersToDate = ({ year = 0, month = 0, day = 0 }) => {
  if (!year && !month && !day) return null;
  const current = moment();
  return current.subtract(year, 'year').subtract(month, 'month').subtract(day, 'day');
};

export const prepareAppPetFromForm = (values) => {
  return {
    _id: values._id || uniqueId(),
    uid: values.uid || undefined,
    pet: {
      _id: values.pet?._id || uniqueId(),
      uid: values.pet?.uid,
      name: values.pet.name,
      type: values.pet.type?.value
        ? {
            uid: values.pet.type.value,
            name: values.pet.type.label
          }
        : null,
      gender: values.pet.gender?.value || null,
      isSterilized: values.pet.isSterilized,
      birthDate: birthDayNumbersToDate(values.pet) || values.pet.birthDate || null
    },
    concerns: values.concerns?.map((c) => ({ uid: c.value, value: c.label })),
    firstTime: values.firstTime,
    files: values.files,
    comment: values.comment || undefined,
    privateComment: values.privateComment || undefined
  };
};

export const prepareAppPetForForm = (pets) => {
  return pets.map((values) => ({
    _id: values._id || uniqueId(),
    uid: values.uid || undefined,
    pet: {
      uid: values.pet?.uid,
      name: values.pet.name,
      type: values.pet.type?.uid
        ? {
            uid: values.pet.type.uid,
            name: values.pet.type.name
          }
        : null,
      gender: values.pet.gender || null,
      isSterilized: values.pet.isSterilized,
      birthDate: values.pet.birthDate ? moment(values.pet.birthDate) : null
    },
    concerns: values.userConcerns?.map((c) => ({
      uid: c.uid,
      value: c.itemValue || c.concern?.name
    })),
    firstTime: values.isFirstVisit,
    files: values.files,
    comment: values.comment || undefined,
    privateComment: values.privateComment || undefined
  }));
};

export function getLast12MonthsStartAndEndDates() {
  const endDate = new Date();
  const startDate = new Date();
  startDate.setMonth(startDate.getMonth() - 12);
  startDate.setDate(1);

  const startOfLast12Months = startDate.toISOString();
  const endOfLast12Months = endDate.toISOString();

  return {
    startDate: startOfLast12Months,
    endDate: endOfLast12Months
  };
}
