import React from 'react';
import { Table } from 'antd';
import { useColumns } from '../lib';
import css from './AppointmentHistoryTable.module.css';
import { useTranslation } from 'react-i18next';

export const AppointmentHistoryTable = ({ data = [], yScroll = { y: 280 }, loading }) => {
  const { t } = useTranslation();
  const columns = useColumns();

  return (
    <div className={css.list}>
      <p className={css.titleText}>{t('admin_dashboard.list_appointments')}</p>
      <Table
        dataSource={data}
        columns={columns}
        scroll={yScroll}
        pagination={false}
        loading={loading}
      />
    </div>
  );
};
