export const SIGN_UP = '/sign-up';
export const SIGN_IN = '/sign-in';
export const SIGN_IN_VET_ADMIN = '/sign-in/clinic';
export const SIGN_IN_USER = '/sign-in/user';
export const SIGN_IN_VET = '/sign-in/vet';
export const VET_ADMIN_DASHBOARD = '/admin';
export const VERIFY_CODE = '/verify-code';
export const HOME = '/home';
export const DASHBOARD = '/';
export const SHOP = '/shop';
export const PROFILE = '/profile';
export const COMPLETE_PROFILE = '/complete-profile';
export const ADD_PET = '/add-pet';
export const MY_PETS = '/my-pets';
export const FINANCE = '/finance';
export const SETTINGS = '/settings';
export const HELP = '/help';
export const PETS = '/pets';
export const SINGLE_PET = '/pets/:id';
export const CHOOSE_PET = '/choose-pet/:id';
export const SELECT_VET = '/select-vet/:id';
export const MEMBERSHIP_DETAILS = '/membership-details/:id';
export const CHECKOUT = '/checkout/:id';
export const TIMELINE = '/timeline';
export const TERMS = '/terms';
export const PRIVACY_POLICY = '/privacy-policy';
export const SCHEDULE = 'schedule';
export const CALENDAR = `/${SCHEDULE}/:firstName-:lastName-:shareId/:placeId?`;
export const BOOK = `/book/:step?`;
export const SANDBOX = `/sandbox`;
export const VET_ADMIN_ROUTES = {
  SCHEDULE: '/clinic/schedule',
  CLIENTS: '/clinic/clients',
  CAREGIVERS: '/clinic/caregivers',
  APP_MANAGER: '/clinic/appt-manager',
  SERVICE_AREA: '/clinic/service-areas',
  INTEGRATIONS: '/clinic/integrations',
  PLANS_AND_USAGE: '/clinic/plans-and-usage'
};

export const VET_ROUTES = {
  VET_DASHBOARD: '/vet-dashboard',
  UPDATE_VET: '/profile',
  CUSTOMERS: '/customers',
  EDIT_PET_OWNER: '/customers/:uid',
  EDIT_PET_OWNER_EXACT: '/customers/',
  ROUTES: '/routes',
  SETTINGS: '/settings'
};

export const ROUTES = {
  SIGN_UP,
  SIGN_IN,
  SIGN_IN_VET,
  SIGN_IN_USER,
  SIGN_IN_VET_ADMIN,
  VERIFY_CODE,
  HOME,
  DASHBOARD,
  PROFILE,
  COMPLETE_PROFILE,
  ADD_PET,
  MY_PETS,
  FINANCE,
  SETTINGS,
  HELP,
  PETS,
  SINGLE_PET,
  CHOOSE_PET,
  SELECT_VET,
  MEMBERSHIP_DETAILS,
  CHECKOUT,
  TIMELINE,
  TERMS,
  PRIVACY_POLICY,
  SCHEDULE,
  CALENDAR,
  BOOK,
  SHOP,
  ...VET_ROUTES,
  ...VET_ADMIN_ROUTES
};

if (process.env.REACT_APP_ENV === 'local') {
  ROUTES['SANDBOX'] = SANDBOX;
}

export const CALENDAR_STEPS = {
  BOOKING_PAGE: '',
  PATIENT_DETAILS: 'patient-details',
  CLIENT_DETAILS: 'client-details',
  SUCCESS: 'success'
};

export const MEMBERSHIP_SEQUENCE = [CHOOSE_PET, MEMBERSHIP_DETAILS, SELECT_VET, CHECKOUT];

export const MEMBERSHIP_STEPS = [
  {
    text: 'select.a.pet',
    route: MEMBERSHIP_SEQUENCE[0]
  },
  {
    text: 'membership.details',
    route: MEMBERSHIP_SEQUENCE[1]
  },
  {
    text: 'select.a.vet',
    route: MEMBERSHIP_SEQUENCE[2]
  },
  {
    text: 'checkout',
    route: MEMBERSHIP_SEQUENCE[3]
  }
];

export const AppointmentRouteStatus = {
  PENDING: 'PENDING',
  UPCOMING: 'UPCOMING',
  STARTED: 'STARTED',
  MISSED: 'MISSED',
  FINISHED: 'FINISHED'
};

export const supportedVideoFormats = [
  'video/mp4',
  'video/x-mp4',
  'video/mpeg2',
  'video/quicktime',
  'video/m4v',
  'video/mpeg'
];
