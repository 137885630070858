import React, { useCallback, useEffect, useMemo, useState } from 'react';
import css from './PetSection.module.css';
import { PlusOutlined } from '@ant-design/icons';
import { Button, Modal, ButtonColors, ButtonType, Label } from '_fsd/shared';
import { PetItem } from '_fsd/entities/pet';
import AppointmentPetDetails from '../AppointmentPetDetails/AppointmentPetDetails';
import AppointmentDetails from '../AppointmentDetails/AppointmentDetails';
import { useQuery } from '@apollo/client';
import { getPetsByUserQuery, getUserConcernsByUserIdQuery } from '../../../gql';
import uniqueId from 'lodash/uniqueId';
import groupBy from 'lodash/groupBy';
import { getOptions } from '../../../utils/helpers';
import RemoveAppointmentPetContent from '../RemoveAppointmentPetContent/RemoveAppointmentPetContent';
import petSvg from 'assets/icons/pet.svg';

const PetSection = ({ client, setPetList, petList, vet, isVet, setPetsToCreate }) => {
  const [selectedPet, setSelectedPet] = useState(null);
  const [petToRemove, setPetToRemove] = useState(false);
  const closeNewPetModal = useCallback(() => setSelectedPet(null), []);
  const { data: { getPetsByUser: petsRaw } = { getPetsByUser: [] } } = useQuery(
    getPetsByUserQuery,
    {
      variables: {
        record: {
          userId: client?.uid
        }
      },
      skip: !client?.uid,
      fetchPolicy: 'cache-and-network'
    }
  );
  const { data: { getUserConcernsByUserId: concernsRaw } = { getUserConcernsByUserId: [] } } =
    useQuery(getUserConcernsByUserIdQuery, {
      variables: {
        id: vet?.user?.uid
      },
      skip: !vet?.user?.uid
    });
  const concerns = useMemo(() => {
    return getOptions(concernsRaw, { value: 'uid', label: (v) => v.itemValue || v.concern?.name });
  }, [concernsRaw]);
  const [pets, setPets] = useState([]);
  useEffect(() => {
    if (petsRaw.length) {
      const petsArray = petsRaw.map((p) => ({
        ...p,
        _id: uniqueId()
      }));
      if (petList[0] && !petList[0]?.pet?._id) {
        setPetList((prev) =>
          prev.map((p) => {
            const petMapped = petsArray.find((a) => a.uid === p.pet.uid);
            let petUpdated = { ...p };
            if (petMapped) {
              petUpdated = {
                ...p,
                pet: {
                  ...p.pet,
                  ...petMapped
                }
              };
            }
            return petUpdated;
          })
        );
      }
      setPets(petsArray);
    }
  }, [petsRaw]);
  useEffect(() => {
    if (petList.length) {
      setPets((prev) => {
        const newPets = [...prev];
        petList.forEach((p) => {
          if (p.pet._id) {
            const index = newPets.findIndex((e) => e._id === p.pet._id);
            if (index >= 0) {
              newPets[index] = p.pet;
            } else {
              newPets.push(p.pet);
            }
          }
        });
        return newPets;
      });
    }
  }, [petList]);

  const onRemove = (v) => {
    setPetList((prev) => prev.filter((p) => p._id !== v._id));
    setPetToRemove(false);
  };
  const map = groupBy(petList, (e) => e.pet._id);

  return (
    <div className={css.container}>
      <div className={css.stack}>
        <div className={css.row}>
          <Label type="h5">Select pets</Label>
          {pets.length ? (
            <Button
              onClick={() => setSelectedPet({ _new: true, pet: {} })}
              type={ButtonType.SECONDARY}
              colorScheme={ButtonColors.BLUE}
              icon={<PlusOutlined />}>
              Add pet
            </Button>
          ) : null}
        </div>
        {pets.length === 0 ? (
          <div className={css.petIcon}>
            <img src={petSvg} alt="bg" />
            <Label>You don't have pets yet</Label>
            <Button
              onClick={() => setSelectedPet({ _new: true, pet: {} })}
              type={ButtonType.SECONDARY}
              colorScheme={ButtonColors.BLUE}
              icon={<PlusOutlined />}>
              Create new pet
            </Button>
          </div>
        ) : (
          <div className={css.wrappedPets}>
            {pets.map((pet) => (
              <PetItem
                forList
                key={pet.uid}
                pet={pet}
                selected={map[pet._id]}
                onClick={() => {
                  if (map[pet._id]) {
                    setPetToRemove(map[pet._id][0]);
                  } else {
                    setSelectedPet({ pet });
                    setPetsToCreate((prev) => prev.filter((p) => p._id !== pet._id));
                  }
                }}
              />
            ))}
          </div>
        )}
        <Label type="h5">Appointment details</Label>
        {petList.length ? (
          <div className={css.wrappedAppointments}>
            {petList.map((appPet) => (
              <AppointmentPetDetails
                isVet={isVet}
                key={appPet.uid}
                appPet={appPet}
                onEdit={setSelectedPet}
                onRemove={setPetToRemove}
              />
            ))}
          </div>
        ) : (
          <div className={css.petIcon}>
            <img src={petSvg} alt="bg" />
            <Label>Select pets for the appointment</Label>
          </div>
        )}
      </div>
      <Modal
        isOpen={!!selectedPet}
        onClose={closeNewPetModal}
        customFooter={() => null}
        title="Appointment details"
        forceRender>
        {selectedPet && (
          <AppointmentDetails
            concerns={concerns}
            isVet={isVet}
            isNew={selectedPet._new}
            appPet={selectedPet}
            onCancel={closeNewPetModal}
            onComplete={(appPet) =>
              setPetList((prev) => {
                const exist = prev.find((e) => e._id === appPet._id);
                if (exist) {
                  return prev.map((e) => {
                    if (e._id === appPet._id) return appPet;
                    return e;
                  });
                } else {
                  return [...prev, appPet];
                }
              })
            }
          />
        )}
      </Modal>

      <Modal
        isOpen={!!petToRemove}
        onClose={() => setPetToRemove(false)}
        customHeader={() => null}
        customFooter={() => null}
        force>
        {petToRemove && (
          <RemoveAppointmentPetContent
            onRemove={(aptPet) => {
              onRemove(aptPet);
              setPetsToCreate((prev) => [...prev, aptPet?.pet]);
            }}
            appPet={petToRemove}
            onClose={() => setPetToRemove(false)}
          />
        )}
      </Modal>
    </div>
  );
};

export default React.memo(PetSection);
